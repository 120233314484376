.modal-feedback {
	max-width: 500px;
	z-index: 99999999;
	position: fixed;
	bottom: 20px;
	left: 20px;

	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px;

	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

	.type {
		height: 30px;
	}

	.info {
		margin: 0px 14px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		h3 {
			margin: 0;
			margin-bottom: 10px;
			font-weight: 400;
		}
		p {
			margin-bottom: 10px;
		}
	}

	.close {
		height: 25px;
		filter: grayscale(100);
		opacity: 65%;
		cursor: pointer;
	}
}
