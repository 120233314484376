$gray-100: #f7f8fa;
$gray-200: #f2f5f9;
$gray-300: #dde3eb;
$gray-400: #c3cad4;
$gray-400: #c3cad4;
$gray-500: #a1abb9;
$gray-600: #7e8794;
$gray-800: #474f5a;
$gray-1000: #263141;

$primary: #5e73e1;
$red: #ff3b30;
$green: #3dd598;

.time-table-head-outer-container {
	display: flex;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 0.9rem;
	width: 100vw;
	height: 36px;

	box-sizing: border-box;
	color: $gray-800;

	.info {
		margin-right: 8px;
		width: 30%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: white;
		border-radius: 8px;
		@media screen and (min-width: 1600px) {
			width: 20%;
		}

		p {
			text-align: center;
			padding: 0rem 0.5rem;
		}
	}

	.h-table-container {
		width: 62%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-radius: 8px;
		@media screen and (min-width: 1600px) {
			width: 72%;
		}
		.h-table-date-container {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 3.5%;
			border-right: 1px solid $gray-100;
			.h-table-date {
				text-align: center;
				margin-bottom: 0px;
			}
			&:last-child {
				border-right: none;
			}
		}
	}

	.statistics-h {
		margin-left: 8px;
		width: 8%;
		// margin-right: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-radius: 8px;
		text-align: center;
		p {
			text-align: center;
			padding: 0rem 0.5rem;
		}
	}
}

.time-table-body-outer-container::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.time-table-body-outer-container {
	max-height: 75vh;
	overflow-y: scroll;
	margin-top: 8px;
	display: flex;
	padding-left: 16px;
	padding-right: 16px;
	font-size: 0.9rem;
	width: 100vw;
	display: flex;
	color: $gray-800;
	box-sizing: border-box;

	.info {
		margin-right: 8px;
		padding-top: 12px;
		padding-bottom: 12px;
		width: 30%;
		// height: 200vh; //! test, change later.

		display: flex;
		gap: 5px;
		flex-direction: column;
		justify-content: center;
		// justify-content: center;
		// align-items: center;

		background-color: white;
		text-align: center;
		border-radius: 8px;
		@media screen and (min-width: 1600px) {
			width: 20%;
		}

		.info-part {
			width: 90%;
			padding: 0px 16px;
			display: flex;
			justify-content: space-between;
			.gray {
				color: $gray-600;
			}
		}
	}

	.h-table-container {
		width: 62%;
		display: flex;
		flex-direction: column;

		@media screen and (min-width: 1600px) {
			width: 72%;
		}

		.presence-container {
			height: 100%;
			width: 100%;
			display: flex;
			background-color: white;
			border-bottom: 1px solid $gray-100;
			border-radius: 8px 8px 0px 0px;

			.h-table-date {
				padding-top: 30px;
				padding-bottom: 30px;

				text-align: center;
				margin-bottom: 0px;
				width: 3.5%;
				border-right: 1px solid $gray-100;
				&:last-child {
					border-right: none;
				}
			}
		}
		.h-table-dates {
			height: 100%;
			width: 100%;
			display: flex;
			background-color: #fff;
			border-radius: 0px 0px 8px 8px;
			.h-table-date {
				padding-top: 32px;
				padding-bottom: 32px;

				text-align: center;
				margin-bottom: 0px;
				width: 3.5%;
				border-right: 1px solid $gray-100;
				&:last-child {
					border-right: none;
				}
			}
		}
	}
	.statistics-h {
		margin-left: 8px;
		width: 8%;
		text-align: center;
		display: flex;
		justify-content: space-between;

		.statistics-info {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			// align-items: ;

			background-color: #fff;
			width: 48%;
			border-radius: 8px;

			.info-part {
				height: 33%;
				width: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
		}

		.edit {
			display: flex;
			justify-content: center;
			align-items: center;

			background-color: #fff;
			width: 48%;
			border-radius: 8px;
			border-color: white;
			transition: all 0.13s ease-in-out;

			&:hover {
				cursor: pointer;
				transition: all 0.13s ease-in-out;
				// border: 1px solid;
				// border-color: rgb(77, 77, 243, 0.5);
				img {
					transform: scale(1.1);
					transition: all 0.13s ease-in-out;
				}
			}

			img {
				transition: all 0.13s ease-in-out;

				width: 22px;
				height: 22px;
			}
		}

		p {
			text-align: center;
			padding: 0rem 0.5rem;
		}
	}
	.green {
		color: $green;
	}
	.red {
		color: $red;
	}
}
