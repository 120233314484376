.upload-container {
	// margin-left: 1rem;

	.instruction {
		.example-download-container {
			position: relative;
			.example-download {
				text-decoration: underline;
				cursor: pointer;
				max-width: fit-content;
				max-width: 300px;
			}
			.info-icon-container {
				position: absolute;
				top: -3px;
				left: 267px;
			}
			.more-dl-info {
				position: absolute;
				top: -13px;
				left: 280px;
				font-size: 0.9rem;
				color: gray;
				transition: all 0.3s ease-in;
			}
		}
	}
}

.fading {
	opacity: 0;
	transition: opacity 0.3s ease-in;
	// display: none;

	&:hover {
		cursor: default;
	}
}

//upload type:file

.upload-file-label {
	background-color: white;
	input[type="file"] {
		color: #a1abb9;
		// if file is uploaded (check in js) make it black
	}
	input[type="file"]::-webkit-file-upload-button,
	input[type="file"]::file-selector-button {
		width: 120px;
		height: 44px;
		margin-left: -10px;
		margin-top: -10px;
		margin-bottom: -10px;
		margin-right: 10px;
		background-color: #5e73e1;
		border: none;
		border-radius: 8px;
		padding: 10px;
		color: white;
	}
}
