body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

body {
  background-color: #f7f8fa;
}

p {
  margin: 0;
}

.logout-nav {
  padding: 0.3rem 0.5rem;
  margin-right: 10px;
  color: #5e73e1;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.logout-nav:hover {
  cursor: pointer;
  background-color: #f7f8fa;
}

.container-fluid {
  padding: 0rem 1rem;
}

.table-container .with-icon {
  display: flex;
  justify-content: start;
  align-items: center;
}

.table-container .with-icon img {
  width: 20px;
  margin-right: 5px;
}

.my-table.choose-table thead th:first-child {
  padding: 18px;
}

.my-table {
  width: 100%;
  font-size: 14px;
  color: #7e8794;
}

.my-table .size-item {
  padding: 24px;
  padding-left: 25px;
}

.my-table thead th:first-child {
  border-radius: 8px 0px 0px 8px;
  padding: 24px;
  padding-left: 25px;
}

.my-table thead th:last-child {
  border-radius: 0px 8px 8px 0px;
}

.my-table thead th {
  text-align: start;
  background-color: #fff;
  color: #474f5a;
  font-weight: 500;
}

.my-table th,
.my-table td {
  padding: 8px 8px;
}

.my-table td {
  border-bottom: 3px solid #f7f8fa;
}

.my-table th {
  font-weight: bold;
  background-color: #f2f2f2;
}

.my-table tbody tr:first-child {
  border: 1px solid blue;
}

.my-table tbody tr:first-child td:first-child {
  border-radius: 8px 0px 0px 0px;
  border-bottom: 3px solid #f7f8fa;
}

.my-table tbody tr:first-child td:last-child {
  border-radius: 0px 8px 0px 0px;
  border-bottom: 3px solid #f7f8fa;
}

.my-table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 8px;
}

.my-table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 8px 0px;
}

.my-table tbody tr:last-child td {
  border: none;
}

.my-table tbody tr {
  background-color: #ffffff;
  transition: all 0.02s ease-in-out;
}

.my-table tbody tr:hover {
  background-color: #f7f8fa;
}

.my-table tbody tr.active {
  background-color: #d5dbfd;
}

.my-table tbody tr.active:hover {
  background-color: #ccd3fc;
}

.my-table tbody:before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}

.d-flex {
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 17px;
}

nav .nav-container {
  display: flex;
  min-height: 68px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
}

nav .navbar-nav {
  display: flex;
  padding: 0rem 1rem;
  margin: 0;
}

nav .navbar-nav a {
  margin-right: 12px;
  padding: 12px 20px !important;
  text-decoration: none;
  color: #7e8794;
}

nav .navbar-nav .navbar-item {
  margin-top: 0;
  list-style: none;
}

nav .navbar-item {
  position: relative;
  transition: all 0.1 ease-in-out;
}

nav .navbar-item .active {
  position: relative;
  color: #5e73e1;
  border-radius: 8px;
  background-color: #f7f8fa;
}

nav .navbar-item .active::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 2px;
  background-color: #5e73e1;
  border-radius: 50px 50px 0px 0px;
}

.nav-item-text {
  position: relative;
}

.nav-item-text .notification-circle {
  position: absolute;
  top: -5px;
  right: -10px;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  opacity: 80%;
}

input {
  overflow-x: hidden;
  padding: 12px;
  padding-top: 15px;
  width: 95%;
  background-color: #f7f8fa;
  border: none;
  border-radius: 8px;
  font-size: 14px;
}

input::-moz-placeholder {
  color: #7e8794;
}

input::placeholder {
  color: #7e8794;
}

input:focus {
  outline: none;
}

label.form-label {
  display: inline-block;
  margin-bottom: 6px;
  color: #474f5a;
  font-size: 14px;
}

.radio-container {
  padding-left: 12px;
}

input[type=radio] {
  min-width: unset;
  margin: 0;
  padding: 2rem !important;
  height: 50px;
  width: 20px;
  border-color: #5e73e1;
  color: #5e73e1;
}

input[type=radio].no-radio-styles {
  min-width: unset;
  margin: 0;
  padding: 0 !important;
  height: 30px;
  width: 20px;
  margin-left: -14px;
  border-color: #5e73e1;
  color: #5e73e1;
}

input.input-error {
  border: 1px solid #ff3b30;
}

.input-validation-text {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  color: #ff3b30;
}

.input-controlled {
  max-width: 99%;
}

.main-modal-outer-container {
  position: fixed;
  display: flex;
  justify-content: end;
  z-index: 100;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.281);
}

.main-modal-outer-container .prevent-click-outside {
  width: 150px;
}

@media screen and (max-width: 1500px) {
  .main-modal-outer-container .prevent-click-outside {
    width: 90px;
  }
}

.main-modal-inner-container::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.main-modal-inner-container {
  position: relative;
  z-index: 1000;
  overflow-y: auto;
  height: 100vh;
  width: 430px;
  background-color: #fff;
  padding: 0.8rem 1rem;
  overflow: -moz-scrollbars-none;
}

.main-modal-inner-container .main-modal-head {
  margin-bottom: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-modal-inner-container .main-modal-head h2 {
  margin-top: 11px;
  font-weight: 500;
  color: #474f5a;
  font-size: 20px;
}

.main-modal-inner-container .main-modal-head svg {
  margin-bottom: 3px;
  margin-right: 0px;
  opacity: 60%;
  cursor: pointer;
}

.main-modal-inner-container .btn-modal-right {
  position: absolute;
  right: 30px;
  bottom: 45px;
  width: 38%;
}

.main-modal-inner-container .btn-modal-left {
  position: absolute;
  left: 30px;
  bottom: 45px;
  width: 38%;
  cursor: pointer;
}

.btn-modal-center {
  position: absolute;
  bottom: 45px;
  width: 90%;
}

.main-modal-inner-container-secondary {
  position: relative;
  z-index: 1000;
  height: 100vh;
  background-color: #fff;
  padding: 0.8rem 1rem;
  border-left: 1px solid #f7f8fa;
}

.main-modal-inner-container-secondary .main-modal-head {
  margin-bottom: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-modal-inner-container-secondary .main-modal-head h2 {
  font-weight: 500;
}

.main-modal-inner-container-secondary .main-modal-head svg {
  opacity: 60%;
  cursor: pointer;
}

.close-animation-outer {
  position: fixed;
  opacity: 100%;
  animation-name: close-to-zero-opacity;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.close-animation-inner {
  position: fixed;
  animation-name: close-to-right;
  animation-duration: 1s;
}

@keyframes close-to-right {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

@keyframes close-to-zero-opacity {
  from {
    background-color: rgba(0, 0, 0, 0.281) !important;
  }

  to {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.btn-form {
  padding: 0.9rem 4rem;
  border-radius: 8px;
  border: none;
  background-color: #5e73e1;
  color: white;
  font-size: 14px;
}

.form-divider {
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 110px;
  left: 0;
  border-bottom: 1px solid #f2f5f9;
}

.dropdown-menu {
  background-color: #fff;
}

.upper-bar {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  background-color: white;
  border-radius: 8px;
}

.upper-bar .upper-bar-first {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.upper-bar .upper-bar-first .filter-btn {
  display: flex;
  margin-left: 10px;
  padding: 0.7rem 0.8rem;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
}

.upper-bar .upper-bar-first .filter-btn:hover {
  background-color: #f7f8fa;
  cursor: pointer;
}

.upper-bar .upper-bar-first .filter-btn p {
  margin: 0;
  margin-left: 8px;
  color: #5e73e1;
}

.upper-bar .upper-bar-first .total-quantity {
  display: flex;
  margin-left: 10px;
  padding: 0.7rem 0.8rem;
  border: 1px solid #f7f8fa;
  border-radius: 8px;
  color: #7e8794;
}

.upper-bar .upper-bar-first .total-quantity p {
  margin: 0;
  padding: 0;
}

.upper-bar .upper-bar-first .total-quantity .quantity {
  color: #5e73e1;
}

.upper-bar .upper-bar-second {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.6rem;
  border-radius: 8px;
  background-color: #fff0ef;
  transition: all 0.15s ease-in-out;
}

.delete-btn:hover {
  background-color: #fddcd9;
  cursor: pointer;
}

.delete-btn img {
  margin-right: 5px;
}

.delete-btn p {
  color: #dc402c;
  font-size: 14px;
}

.common-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.79rem 0.6rem;
  border-radius: 8px;
  background-color: #5e73e1;
  transition: all 0.15s ease-in-out;
}

.common-btn:not(.disabled):hover {
  background-color: #4b63da;
  cursor: pointer;
}

.common-btn.common.disabled {
  cursor: not-allowed;
  background-color: #5e74e1a1;
}

.common-btn img {
  margin-right: 5px;
}

.common-btn p {
  color: white;
  font-size: 14px;
}

.common-btn.light {
  background-color: #e7eafb;
}

.common-btn.light p {
  color: #5e73e1;
}

.common-btn.light:hover {
  background-color: #d5daf8;
  cursor: pointer;
}

.uncheck-all-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 0.6rem;
  border-radius: 8px;
  background-color: #e7eafb;
  transition: all 0.15s ease-in-out;
}

.uncheck-all-btn:hover {
  background-color: #d1d7f3;
  cursor: pointer;
}

.uncheck-all-btn img {
  margin-right: 5px;
}

.uncheck-all-btn p {
  margin: 0;
  font-size: 14px;
  color: #5e73e1;
}

.info-switch {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f2f5f9;
  height: 55px;
  border-radius: 8px;
  color: #7e8794;
}

.info-switch p {
  max-width: 74px;
  max-height: 20px;
  padding: 13px 71px;
  border-radius: 4px;
  margin-bottom: 1px;
  cursor: pointer;
  transition: 0.2s all;
}

.info-switch .director {
  background-color: #fff;
}

.info-switch .active {
  color: #5e73e1;
  background-color: white;
}

.more-info-container {
  font-size: 14px;
}

.more-info-container .more-info-item {
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f5f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.more-info-container .more-info-item .more-item-name {
  color: #7e8794;
}

.more-info-container .more-info-item .more-item-content {
  text-align: end;
  max-width: 60%;
  color: #263141;
}

.more-info-container.scrolled {
  max-height: 76vh !important;
  height: 76vh !important;
  overflow: scroll;
}

@media screen and (max-height: 1000px) {
  .more-info-container.scrolled {
    height: 74vh !important;
    max-height: 74% !important;
  }
}

@media screen and (max-height: 950px) {
  .more-info-container.scrolled {
    height: 71vh !important;
    max-height: 71% !important;
  }
}

@media screen and (max-height: 826px) {
  .more-info-container.scrolled {
    height: 69vh !important;
    max-height: 69% !important;
  }
}

@media screen and (max-height: 770px) {
  .more-info-container.scrolled {
    height: 67vh !important;
    max-height: 67% !important;
  }
}

.scrolled::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.more-info-container.scrolled-2 {
  max-height: 83vh !important;
  height: 83vh !important;
  overflow: scroll;
}

@media screen and (max-height: 1020px) {
  .more-info-container.scrolled-2 {
    height: 81vh !important;
    max-height: 81% !important;
  }
}

@media screen and (max-height: 950px) {
  .more-info-container.scrolled-2 {
    height: 80vh !important;
    max-height: 80% !important;
  }
}

@media screen and (max-height: 826px) {
  .more-info-container.scrolled-2 {
    height: 78vh !important;
    max-height: 78% !important;
  }
}

@media screen and (max-height: 770px) {
  .more-info-container.scrolled-2 {
    height: 77vh !important;
    max-height: 77% !important;
  }
}

@media screen and (max-height: 730px) {
  .more-info-container.scrolled-2 {
    height: 75vh !important;
    max-height: 75% !important;
  }
}

.scrolled-2::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.more-info-container.scrolled::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

@media screen and (min-height: 850px) {
  .candidate-comment {
    position: absolute;
    bottom: 140px;
    width: 93%;
  }
}

.more-info-container.scrolled-3 {
  max-height: 70vh !important;
  height: 70vh !important;
  overflow: scroll;
}

@media screen and (max-height: 1020px) {
  .more-info-container.scrolled-3 {
    height: 67vh !important;
    max-height: 67% !important;
  }
}

@media screen and (max-height: 950px) {
  .more-info-container.scrolled-3 {
    height: 62vh !important;
    max-height: 62% !important;
  }
}

@media screen and (max-height: 826px) {
  .more-info-container.scrolled-3 {
    height: 58vh !important;
    max-height: 58% !important;
  }
}

@media screen and (max-height: 770px) {
  .more-info-container.scrolled-3 {
    height: 55vh !important;
    max-height: 55% !important;
  }
}

@media screen and (max-height: 730px) {
  .more-info-container.scrolled-3 {
    height: 53vh !important;
    max-height: 53% !important;
  }
}

.scrolled-3::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.hidden-info .blurred {
  filter: blur(4px);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.hidden-info .items {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.hidden-info .items .gray {
  color: #7e8794;
}

.hidden-info .show-btn {
  margin-top: 18px;
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: right;
  color: #5e73e1;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.hidden-info .show-btn p {
  padding-left: 4px;
}

.hidden-info .show-btn p,
.hidden-info .show-btn img {
  cursor: pointer;
}

.hidden-info .generate-creds {
  margin-top: 8px;
  color: #5e73e1;
  text-align: right;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.hidden-info .generate-creds:hover {
  color: #2e4bdc;
}

.rbt {
  position: relative;
}

.rbt .dropdown-menu {
  overflow-x: hidden;
  margin-top: 4px;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.rbt .dropdown-menu .dropdown-item {
  font-size: 14px;
  text-decoration: none;
  color: #474f5a;
}

.rbt .dropdown-menu .dropdown-item div {
  padding: 0.8rem 0.8rem;
}

.rbt .dropdown-menu .dropdown-item div:hover {
  background-color: #f7f8fa;
}

.rbt-input-main[disabled=""] {
  background-color: #dde3eb;
  overflow-x: hidden;
}

.filter-input {
  margin-bottom: 20px;
}

.filter-autocomplete-label {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 4px;
  color: #474f5a;
}

.close-typeahead-filter {
  position: absolute;
  right: 13px;
  top: 55%;
  transform: translateY(-50%);
  cursor: pointer;
  filter: grayscale(100);
  opacity: 50%;
}

.typeahead-down-arrow {
  position: absolute;
  right: 12px;
  top: 55%;
  transform: translateY(-50%);
}

.typeahead-down-arrow-not-filter {
  position: absolute;
  right: 14px;
  top: 40%;
  transform: translateY(-50%);
}

.example-download-container {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  cursor: pointer;
  color: #5e73e1;
  transition: all 0.1s ease-in-out;
}

.example-download-container:hover {
  opacity: 80%;
}

.example-download-container img {
  margin-right: 10px;
}

.upload-errors {
  max-height: 65vh !important;
  overflow-y: scroll;
  margin-top: 1.5rem;
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.upload-errors::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.text-danger {
  color: #ff3b30;
}

button[disabled=""] {
  cursor: not-allowed !important;
  opacity: 50%;
}

.page-navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
}

.page-navigation .items-shown {
  color: gray;
}

.pagination {
  display: flex;
  margin-left: -100px;
}

.pagination .page-item {
  text-align: center;
  list-style: none;
  margin-right: 6px;
  min-width: 20px;
  padding: 9px 9px;
  color: #474f5a;
  border-radius: 8px;
  transition: all 0.1s ease-out;
  cursor: pointer;
}

.pagination .page-item:hover {
  background-color: #eceefa;
}

.pagination .page-item.pagination-navigate {
  color: #5e73e1;
}

.pagination .page-item.active {
  color: white;
  background-color: #5e73e1;
}

.timesheet-container {
  min-height: 100%;
}

.timesheet-container .worker-name {
  margin-top: 11px;
  color: #474f5a;
  font-size: 20px;
  font-weight: 500;
}

.timesheet-container .day-of-week {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 14px;
  background-color: #f7f8fa;
  height: 40px;
  font-weight: 600;
  font-size: 20px;
  color: #7e8794;
  border-radius: 8px;
}

.timesheet-container .day-of-week.dayoff {
  color: #263141;
  margin-bottom: 0;
}

.timesheet-container .day.no-edit {
  background-color: #dde3eb;
  opacity: 80%;
}

.timesheet-container .day.chosen.no-edit {
  outline: none;
  cursor: default;
}

.timesheet-container .day {
  position: relative;
  background-color: #f7f8fa;
  width: 118px;
  max-width: 118px;
  height: 92px;
  border-radius: 8px;
  text-align: center;
  color: #474f5a;
  font-size: 14px;
  cursor: pointer;
}

.timesheet-container .day .lock {
  position: absolute;
  right: 5px;
  top: 5px;
  opacity: 40%;
}

.timesheet-container .day p {
  margin: 0;
  padding-top: 1px;
}

.timesheet-container .day .hours-status {
  color: #7e8794;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: 500;
}

.timesheet-container .day .day-num {
  padding-top: 7px;
}

@media screen and (max-height: 900px) {
  .timesheet-container .day {
    height: 70px;
    margin: 1px;
  }

  .timesheet-container .day p {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  .timesheet-container .day .day-num {
    padding: 0;
    padding-top: 5px;
  }

  .timesheet-container .day .day-status {
    padding: 1px 0;
  }

  .timesheet-container .day .day-hours {
    padding: 0;
    padding-bottom: 5px;
  }
}

.timesheet-container .day .red {
  color: #ff3b30;
}

.timesheet-container .day .green {
  color: #3dd598;
}

.timesheet-container .select-chosen.gray.green {
  color: #474f5a;
}

.timesheet-container .day.chosen {
  outline: 1px dashed #5e73e1;
}

.timesheet-container .choose-year-month {
  width: 100%;
  display: flex;
  gap: 10px;
}

.timesheet-container .timesheet-set-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  position: absolute;
  bottom: 46px;
  left: 16px;
}

.select-custom-container {
  position: relative;
}

.select-custom-container .select-chosen {
  min-height: 17px;
  position: relative;
  color: #263141;
  padding: 0.8rem 0.8rem;
  background-color: #f7f8fa;
  border-radius: 8px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.select-custom-container .select-chosen .arrow-down {
  position: absolute;
  top: 30%;
  right: 7.5%;
}

.select-custom-container .select-chosen.error {
  border: 1px solid #ff3b30;
}

.select-custom-container .select-chosen.green {
  color: #3dd598;
}

.select-custom-container .select-chosen.red {
  color: #ff3b30;
}

.select-custom-container .select-chosen.is-placeholder {
  color: #abb1b8;
  font-weight: 300;
}

.select-custom-container .dropdown-menu {
  z-index: 9999999999;
  position: absolute;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 4px;
  width: 100%;
  top: 40px;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.select-custom-container .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #f7f8fa;
  font-size: 14px;
  text-decoration: none;
  color: #474f5a;
  cursor: pointer;
}

.select-custom-container .dropdown-menu .dropdown-item div {
  padding: 0.8rem 0.8rem;
}

.select-custom-container .dropdown-menu .dropdown-item div:hover {
  background-color: #f7f8fa;
}

.select-custom-container .dropdown-menu .dropdown-item.green {
  color: #3dd598;
}

.select-custom-container .dropdown-menu .dropdown-item.red {
  color: #ff3b30;
}

.select-custom-container .dropdown-menu.inverse {
  max-height: 120px;
  top: -140px;
  left: 0;
}

.select-custom-container .dropdown-menu.inverse .dropdown-item div {
  padding: 0.6rem 0.8rem;
}

.divider-sm {
  width: 100%;
  border-top: 1px solid #f7f8fa;
  height: 15px;
}

.hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.hide-scrollbar ::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.black-screen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.confirm-modal {
  position: relative;
  min-width: 310px;
  max-width: 310px;
  padding: 20px;
  padding-top: 25px;
  background-color: #fff;
  border-radius: 12px;
}

.confirm-modal .title {
  margin-top: -8px;
  font-size: 20px;
  color: #474f5a;
  margin-bottom: 16px;
}

.confirm-modal .text {
  max-width: 90%;
  color: #474f5a;
  font-size: 14px;
  margin-bottom: 16px;
}

.confirm-modal h3,
.confirm-modal p {
  margin: 0;
}

.confirm-modal img {
  position: absolute;
  top: 8px;
  right: 5px;
  padding: 10px;
  transform: scale(1.2);
  filter: grayscale(100);
  opacity: 70%;
  cursor: pointer;
}

.confirm-modal .confirm-buttons {
  display: flex;
  justify-content: space-between;
}

.confirm-modal .confirm-buttons .delete-btn {
  width: 128px;
  height: 23px;
}

.confirm-modal .confirm-buttons .common-btn {
  width: 128px;
  height: 21px;
}

.candidates-list {
  padding: 16px;
  padding-top: 22px;
  margin-bottom: 12px;
  background-color: #f7f8fa;
  border-radius: 8px;
  font-size: 14px;
}

.candidates-list:hover {
  background-color: #f2f5f9;
  cursor: pointer;
}

.candidates-list .info {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  color: #263141;
}

.candidates-list .info :nth-child(1) {
  color: #7e8794;
}

.candidates-list.active {
  outline: 1px dashed #5e73e1;
}

.employee-req-secondary {
  position: relative;
  z-index: 1000;
  height: 100vh;
  width: 430px;
  max-width: 430px;
  background-color: #fff;
  padding: 0.8rem 1rem;
}

.employee-req-secondary .main-modal-head {
  margin-bottom: 1.3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employee-req-secondary .main-modal-head h2 {
  font-weight: 500;
  font-size: 20px;
  color: #474f5a;
  margin: 0;
}

.employee-req-secondary .main-modal-head svg {
  opacity: 60%;
  cursor: pointer;
}

.employee-req-secondary .divider {
  position: absolute;
  bottom: 105px;
  left: -16px;
  min-width: 1000px;
  margin-right: 60px;
  border-bottom: 1px solid #f7f8fa;
  margin-bottom: 20px;
}

.employee-req-secondary .bottom-part {
  position: absolute;
  bottom: 60px;
  left: 0;
  width: 100%;
  margin: auto;
}

.employee-req-secondary .bottom-part .common-btn {
  margin: auto;
  width: 90%;
}

.employee-req-secondary .bottom-part .buttons-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-req-secondary .bottom-part .buttons-flex .common-btn {
  margin: auto;
  width: 42%;
}

.employee-req-secondary .input-validation-text {
  margin-top: 0rem;
}

.editReq .mt-2 {
  margin-top: 8px;
}

.icon-input-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-input-filter input {
  width: 240px;
}

.icon-input-filter .filter-input {
  display: inline;
  width: 10px;
  margin-left: -4px;
}

.icon-input-filter .icon {
  width: 30px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f8fa;
  border-radius: 8px 0px 0px 8px;
}

.icon-input-filter .icon img {
  padding-left: 5px;
}

.icon-input-filter img {
  width: 18px;
}

.divider {
  position: absolute;
  bottom: 45px;
  left: -16px;
  min-width: 1000px;
  margin-right: 60px;
  border-bottom: 1px solid #f7f8fa;
  margin-bottom: 20px;
}

.login-container {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login-container .login-inputs-container {
  margin: auto;
}

.login-container .login-inputs-container input {
  margin-bottom: 10px;
  width: 300px;
  height: 20px;
  font-size: 17px;
  font-weight: 300;
}

.login-container .login-inputs-container .btn-login {
  margin-top: 0px;
  padding: 0.7rem 0rem;
  border: none;
  background-color: #5e73e1;
  color: white;
  font-size: 17px;
  width: 100%;
  border-radius: 8px;
}

.login-container .login-inputs-container .btn-login:hover {
  cursor: pointer;
  background-color: #4861df;
}

.login-container .login-inputs-container .btn-login:active {
  background-color: #2e4bdc;
}

.login-container .login-inputs-container .login-error {
  margin-top: 10px;
  color: #ff3b30;
}

.multiple-choice .label-quantity {
  display: flex;
  justify-content: space-between;
}

.multiple-choice .label-quantity p {
  color: #474f5a;
  font-size: 14px;
}

.multiple-container {
  position: relative;
}

.multiple-container .select-chosen.error {
  border: 1px solid #ff3b30;
}

.multiple-container .select-chosen {
  min-height: 17px;
  max-height: 100px;
  overflow-y: scroll;
  position: relative;
  color: #263141;
  padding: 0.8rem 0.8rem;
  background-color: #f7f8fa;
  border-radius: 8px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.multiple-container .select-chosen .arrow-down {
  position: absolute;
  top: 13px;
  right: 7.5%;
}

.multiple-container .select-chosen .chosen-options-container {
  z-index: 9999999;
  max-width: 92%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.multiple-container .select-chosen .chosen-options-container .chosen-options {
  min-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.4rem;
  color: #5e73e1;
  background-color: #e4e9fd;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.multiple-container .select-chosen .chosen-options-container .chosen-options:hover {
  background-color: #cad4fb;
}

.multiple-container .select-chosen .chosen-options-container .chosen-options img {
  margin-left: 3px;
  height: 18px;
  filter: grayscale(100);
}

.multiple-container .select-chosen::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.multiple-container .all {
  display: flex;
}

.multiple-container .all p {
  padding: 5px 10px 0px 0px;
  font-size: 14px;
  color: #474f5a;
  transition: all 0.1s ease-in-out;
}

.multiple-container .all p:hover {
  color: #263141;
  cursor: pointer;
}

.multiple-container .all p.choose-all {
  color: #5e73e1;
}

.multiple-container .dropdown-menu {
  position: absolute;
  max-width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 4px;
  z-index: 100;
  width: 100%;
  left: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}

.multiple-container .dropdown-menu .dropdown-item {
  border-bottom: 1px solid #f7f8fa;
  font-size: 14px;
  text-decoration: none;
  color: #474f5a;
}

.multiple-container .dropdown-menu .dropdown-item div:hover {
  background-color: #f7f8fa;
}

.multiple-container .dropdown-menu .dropdown-item .dropdown-item-flex {
  display: flex;
  align-items: center;
}

.multiple-container .dropdown-menu .dropdown-item .dropdown-item-flex img {
  margin-top: 4px;
  margin-right: 10px;
}

.multiple-container .dropdown-menu .dropdown-item div {
  padding: 0.4rem 0.8rem;
}

.loading {
  position: relative;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading .kitty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -54%);
  border-radius: 99999999px;
  opacity: 70%;
}

.loading .loader {
  animation: spin 1.7s linear infinite;
  z-index: 10;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-inside {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-inside .kitty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 99999999px;
  opacity: 70%;
}

.loading-inside .loader {
  animation: spin 1.7s linear infinite;
  z-index: 10;
}

div.outlet-create-no-scroll {
  height: 80vh !important;
  min-height: 0vh !important;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

div.outlet-create-no-scroll::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

div.outlet-create-no-scroll::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.color-gray-text {
  color: #263141;
}

.side-modal-container .center-container {
  max-height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-height: 980px) {
  .side-modal-container .center-container {
    max-height: 70vh;
  }
}

@media screen and (max-height: 870px) {
  .side-modal-container .center-container {
    max-height: 65vh;
  }
}

.side-modal-container .center-container:root {
  scrollbar-width: none !important;
}

.side-modal-container .bottom-container {
  position: absolute;
  width: 95%;
  bottom: 40px;
  left: 12px;
  scrollbar-width: none;
}

.side-modal-container .center-container::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.side-modal-container .center-container-2 {
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-modal-container .bottom-container-2 {
  position: absolute;
  width: 95%;
  bottom: 40px;
  left: 12px;
  scrollbar-width: none;
}

.side-modal-container .bottom-container-2 .flex {
  display: flex;
  justify-content: space-around;
}

.side-modal-container .bottom-container-2 .flex .common-btn {
  width: 42%;
  height: 17px;
}

.side-modal-container .bgred {
  background-color: red;
}

.side-modal-container .center-container-2::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.side-modal-container .center-container-3 {
  max-height: 77vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.side-modal-container .bottom-container-3 {
  position: absolute;
  width: 95%;
  bottom: 40px;
  left: 12px;
  scrollbar-width: none;
}

.side-modal-container .bottom-container-3 .flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.side-modal-container .bottom-container-3 .flex .common-btn {
  width: 42%;
  height: 17px;
}

.side-modal-container .center-container-3::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.flex-employee-info {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 93%;
  bottom: 40px;
}

.flex-employee-info .common-btn {
  width: 44%;
}

@media screen and (max-width: 1430px) {
  .flex-employee-info .common-btn p {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1300px) {
  .table-responsive-sm {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1200px) {
  .table-responsive-sm {
    font-size: 0.7rem;
  }
}

input.general-search-sm {
  width: 140px;
}

.employees-working-period-block {
  display: flex;
  margin: auto;
  margin-left: 15px;
}

.employees-working-period-block .start {
  position: relative;
}

.employees-working-period-block .end {
  position: relative;
  margin-left: 10px;
}

.employees-working-period-block input {
  width: 130px;
}

.employees-working-period-block .period-label {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #7e8794;
  font-size: 0.64rem;
  text-align: center;
}

.hrs-confirm-btns-container {
  display: flex;
  gap: 20px;
}

.hrs-confirm-btns-container .hrs-confirm-btn {
  color: white;
  padding: 12px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.hrs-confirm-btns-container .positive {
  color: #5e73e1;
  transition: all 0.3s ease-in-out;
  opacity: 40%;
}

.hrs-confirm-btns-container .positive.active {
  color: #5e73e1;
  opacity: 100%;
  background-color: #e7eafb;
  color: #5e73e1;
}

.hrs-confirm-btns-container .positive.active:hover {
  background-color: #bac4f1;
}

.hrs-confirm-btns-container .negative {
  opacity: 40%;
  color: #dc402c;
  background-color: #fddcd9;
  transition: all 0.3s ease-in-out;
}

.hrs-confirm-btns-container .negative.active {
  opacity: 100%;
}

.hrs-confirm-btns-container .negative.active:hover {
  background-color: #fabbb5;
}

@media screen and (max-height: 1000px) {
  .divider-hide-if-screen-low-height {
    display: none;
  }
}

.heading-container {
  position: relative;
}

.heading-container .notification-dot {
  position: absolute;
  top: -10px;
  right: 25px;
  height: 12px;
  width: 12px;
  opacity: 75%;
  border-radius: 100px;
}

/*# sourceMappingURL=general.css.map */

.user-card {
  background-color: #f7f8fa;
  margin: 3px;
  border-radius: 10px;
  padding: 7px 14px;
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  gap: 15px;
      font-size: 14px;
  color: #7e8794;
}

.user-card > div:nth-child(1) {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.user-card .exit-btn {
  color: white;
  padding: 9px 6px;
  padding-right: 0px;
}