.upload-container .instruction .example-download-container {
  position: relative;
}
.upload-container .instruction .example-download-container .example-download {
  text-decoration: underline;
  cursor: pointer;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-width: 300px;
}
.upload-container .instruction .example-download-container .info-icon-container {
  position: absolute;
  top: -3px;
  left: 267px;
}
.upload-container .instruction .example-download-container .more-dl-info {
  position: absolute;
  top: -13px;
  left: 280px;
  font-size: 0.9rem;
  color: gray;
  transition: all 0.3s ease-in;
}

.fading {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
.fading:hover {
  cursor: default;
}

.upload-file-label {
  background-color: white;
}
.upload-file-label input[type=file] {
  color: #a1abb9;
}
.upload-file-label input[type=file]::-webkit-file-upload-button,
.upload-file-label input[type=file]::file-selector-button {
  width: 120px;
  height: 44px;
  margin-left: -10px;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 10px;
  background-color: #5e73e1;
  border: none;
  border-radius: 8px;
  padding: 10px;
  color: white;
}/*# sourceMappingURL=uploadInstructions.css.map */