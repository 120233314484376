// .modal-candidate .hidden {
// 	opacity: 0;
// }
.modal-candidate {
	z-index: 100;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.372);
	// transition: allrgba(0, 0, 0, 0.359)ase-in-out;

	.modal-candidate-inner {
		position: relative;
		height: 700px;
		width: 500px;
		overflow-y: scroll;
		background-color: rgb(243, 242, 242);
		border-radius: 10px;
		box-shadow: 0px 5px 10px 2px rgba(13, 13, 13, 0.2);
		transition: all 200ms ease-in;
		&:hover {
			box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.37);
		}

		.modal-candidate-content-container {
			margin: 1rem 2rem;
			.gray-text-mini {
				color: rgb(99, 99, 99);
				margin-bottom: 2px;
			}
			h3 {
				text-align: center;
				margin-top: 2rem;
				margin-bottom: 2.5rem;
			}

			h4 {
				font-size: 1.3rem;
				margin-bottom: 1.2rem;
			}
			.rbt {
				margin-top: 0.5rem;
			}

			select {
				margin-bottom: 0.6rem;
			}

			textarea {
				max-height: 100px;
			}
			.btn-custom-form {
				position: absolute;
				bottom: 30px;
				left: 50%;
				transform: translateX(-50%);
				padding: 1rem 2.5rem;
				background-color: rgb(52, 71, 237);
				color: white;
				font-size: 1rem;
				border-radius: 5px;

				align-self: end;
				justify-self: end;

				cursor: pointer;

				&:hover {
					background-color: rgb(27, 40, 154);
				}
			}
			.close-btn-modal {
				cursor: pointer;
				position: absolute;
				top: 15px;
				right: 20px;
			}

			// other
			a {
				display: block;
				text-decoration: none;
				color: black;
			}
			.scroll-w {
				overflow-y: scroll;

				max-height: 400px;
				.contractor-container {
					border: 1px solid rgb(212, 212, 212);
					padding: 1rem 2rem;
					background-color: rgb(237, 237, 237);
					box-shadow: 0px 0px 2px 3px rgba(237, 236, 236, 0.2);
					border-radius: 5px;
					margin-bottom: 1rem;
					transition: all 200ms linear;
					cursor: pointer;
					&:hover {
						box-shadow: 0px 0px 3px 0px rgba(53, 53, 53, 0.2);
					}
				}
				&::-webkit-scrollbar-thumb {
					display: none !important;
					width: 20px;
				}
				&::-webkit-scrollbar {
					display: none !important;
				}

				p {
					margin-bottom: 10px;
				}
			}

			.toOtherCandidatesBtn {
				position: absolute;
				bottom: 30px;
				left: 50%;
				transform: translateX(-50%);
				text-align: center;
				color: white;
				font-size: 1.1rem;
				padding: 0.5rem 4rem;
				background-color: rgb(87, 130, 247);
				border-radius: 10px;

				&:hover {
					background-color: rgb(67, 114, 245);
				}
			}
		}
	}
}

.show-responsible-text {
	text-decoration: underline;
	cursor: pointer;
}
