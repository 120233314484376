$gray-100: #f7f8fa;
$gray-200: #f2f5f9;
$gray-300: #dde3eb;
$gray-400: #c3cad4;
$gray-400: #c3cad4;
$gray-500: #a1abb9;
$gray-600: #7e8794;
$gray-800: #474f5a;
$gray-1000: #263141;

$primary: #5e73e1;
$red: #ff3b30;
$green: #3dd598;

body {
	//! hide scrollbar. Idk how it works
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

// to imoirt need rename _general i think
body {
	background-color: $gray-100;
}

p {
	margin: 0;
}

.logout-nav {
	padding: 0.3rem 0.5rem;
	margin-right: 10px;
	color: $primary;
	transition: all 0.2s ease-in-out;
	border-radius: 4px;

	&:hover {
		cursor: pointer;
		background-color: $gray-100;
	}
}

.container-fluid {
	padding: 0rem 1rem;
}

//* table

.table-container {
	.with-icon {
		display: flex;
		justify-content: start;
		align-items: center;

		img {
			width: 20px;
			margin-right: 5px;
		}
	}
}

.my-table.choose-table {

	// for radio btns in head
	thead {
		th:first-child {
			padding: 18px; // makes table row bigger
		}
	}
}

.my-table {
	.size-item {
		padding: 24px; // makes table row bigger
		padding-left: 25px; // shifts the table
	}

	width: 100%;
	// seems important.
	// border-collapse: collapse;
	font-size: 14px;
	color: $gray-600;

	thead {
		th:first-child {
			border-radius: 8px 0px 0px 8px;
			padding: 24px; // makes table row bigger
			padding-left: 25px; // shifts the table
		}

		th:last-child {
			border-radius: 0px 8px 8px 0px;
		}

		th {
			text-align: start;
			background-color: #fff;
			color: $gray-800;
			font-weight: 500; // change when the font is changed
		}
	}

	th,
	td {
		padding: 8px 8px;
		// padding: 1.2rem;

		//!padding
		// border-bottom: 3px solid $gray-100;
		//!border
	}

	td {
		border-bottom: 3px solid $gray-100;
	}

	th {
		font-weight: bold;
		background-color: #f2f2f2;
	}

	tbody {
		tr:first-child {
			border: 1px solid blue; // doesnt work
			// border-radius: 20px;
		}

		tr:first-child td:first-child {
			// border: 2px 0px 0px 0px solid red; //!WORKS IN CHROME FUCK FIREFOX
			border-radius: 8px 0px 0px 0px;
			border-bottom: 3px solid $gray-100;
		}

		tr:first-child td:last-child {
			// border: 2px 0px 0px 0px solid red; //!WORKS IN CHROME FUCK FIREFOX
			border-radius: 0px 8px 0px 0px;
			border-bottom: 3px solid $gray-100;
		}

		tr:last-child td:first-child {
			border-radius: 0px 0px 0px 8px;
		}

		tr:last-child td:last-child {
			border-radius: 0px 0px 8px 0px;
		}

		tr:last-child td {
			border: none;
		}

		tr {
			// border-radius: 10px;
			background-color: #ffffff;
			transition: all 0.02s ease-in-out;

			&:hover {
				background-color: $gray-100;
			}
		}

		tr.active {
			background-color: #d5dbfd;

			&:hover {
				background-color: #ccd3fc;
			}
		}
	}

	tbody:before {
		content: "@";
		display: block;
		line-height: 10px;
		text-indent: -99999px;
	}
}

// stuff inside th like sort
.d-flex {
	display: flex;
	justify-content: start;
	align-items: center;
	max-height: 17px;
}

//* nav

nav {
	.nav-container {
		display: flex;
		min-height: 68px;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		background-color: white;
	}

	.navbar-nav {
		display: flex;
		padding: 0rem 1rem;
		margin: 0;

		a {
			margin-right: 12px;
			padding: 12px 20px !important;
			text-decoration: none;
			color: $gray-600;
		}

		.navbar-item {
			margin-top: 0;
			list-style: none;
		}
	}

	.navbar-item {
		position: relative;
		transition: all 0.1 ease-in-out;

		.active {
			position: relative;
			color: $primary;
			// border-bottom: 1px solid rgb(0, 0, 0) !important;
			border-radius: 8px;
			background-color: $gray-100;
		}

		.active::before {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 50%;
			transform: translateX(-50%);
			width: 36px;
			height: 2px;
			background-color: $primary;
			border-radius: 50px 50px 0px 0px;
		}
	}
}

.nav-item-text {
	position: relative;

	.notification-circle {
		position: absolute;
		top: -5px;
		right: -10px;
		height: 10px;
		width: 10px;
		// background-color: $red;
		border-radius: 100px;
		opacity: 80%;
	}
}

// inputs

input {
	overflow-x: hidden;
	padding: 12px;
	padding-top: 15px;
	// min-width: 392px;
	width: 95%; //! pretty important
	background-color: $gray-100;
	border: none;
	border-radius: 8px;
	font-size: 14px;

	&::placeholder {
		color: $gray-600;
	}

	&:focus {
		outline: none;
	}
}

label.form-label {
	display: inline-block;
	margin-bottom: 6px;
	color: $gray-800;
	font-size: 14px;
}

.radio-container {
	padding-left: 12px;
}

input[type="radio"] {
	min-width: unset;
	margin: 0;
	padding: 2rem !important;
	height: 50px;
	width: 20px;
	border-color: $primary;
	color: $primary;
}

input[type="radio"].no-radio-styles {
	min-width: unset;
	margin: 0;
	padding: 0 !important;
	height: 30px;
	width: 20px;
	margin-left: -14px;
	border-color: $primary;
	color: $primary;
}

input.input-error {
	border: 1px solid $red;
}

.input-validation-text {
	margin-top: -1rem;
	margin-bottom: 1rem;
	font-size: 12px;
	color: $red;
}

.input-controlled {
	max-width: 99%;
}

.modal-first {
	// transform: translateX(-80%);
}

.main-modal-outer-container {
	position: fixed;
	display: flex;
	justify-content: end;
	z-index: 100;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.281);

	.prevent-click-outside {
		width: 150px;

		@media screen and (max-width: 1500px) {
			width: 90px;
		}
	}
}

.main-modal-inner-container::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.main-modal-inner-container {
	position: relative;
	z-index: 1000;
	overflow-y: auto;
	height: 100vh;
	width: 430px;
	background-color: #fff;

	padding: 0.8rem 1rem;
	overflow: -moz-scrollbars-none;

	.main-modal-head {
		margin-bottom: 1.3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			margin-top: 11px;
			font-weight: 500;
			color: $gray-800;
			font-size: 20px;
		}

		svg {
			margin-bottom: 3px;
			margin-right: 0px;
			opacity: 60%;
			cursor: pointer;
		}
	}

	.btn-modal-right {
		position: absolute;
		right: 30px;
		bottom: 45px;
		width: 38%;
	}

	.btn-modal-left {
		position: absolute;
		left: 30px;
		bottom: 45px;
		width: 38%;
		cursor: pointer;
	}
}

.btn-modal-center {
	position: absolute;
	bottom: 45px;
	width: 90%;
}

// antidry secondary modal
.main-modal-inner-container-secondary {
	position: relative;
	z-index: 1000;
	height: 100vh;
	// width: 900px;
	background-color: #fff;
	padding: 0.8rem 1rem;
	border-left: 1px solid $gray-100;

	.main-modal-head {
		margin-bottom: 1.3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-weight: 500;
		}

		svg {
			opacity: 60%;
			cursor: pointer;
		}
	}
}

.close-animation-outer {
	position: fixed;
	opacity: 100%;
	animation-name: close-to-zero-opacity;
	animation-duration: 1s;
	animation-fill-mode: forwards;
}

.close-animation-inner {
	position: fixed;
	animation-name: close-to-right;
	animation-duration: 1s;
}

@keyframes close-to-right {
	from {
		right: 0;
	}

	to {
		right: -100%;
	}
}

@keyframes close-to-zero-opacity {
	from {
		background-color: rgba(0, 0, 0, 0.281) !important;
	}

	to {
		background-color: rgba(0, 0, 0, 0) !important;
	}
}

.btn-form {
	padding: 0.9rem 4rem;
	border-radius: 8px;
	border: none;
	background-color: $primary;
	color: white;
	font-size: 14px;
}

.form-divider {
	position: absolute;
	height: 1px;
	width: 100%;
	bottom: 110px;
	left: 0;
	border-bottom: 1px solid $gray-200;
}

.dropdown-menu {
	background-color: #fff;
}

//* upper bar

.upper-bar {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 76px;
	background-color: white;
	border-radius: 8px;

	.upper-bar-first {
		display: flex;
		align-items: center;
		margin-left: 16px;

		.filter-btn {
			display: flex;
			margin-left: 10px;
			padding: 0.7rem 0.8rem;
			border-radius: 8px;
			transition: all 0.15s ease-in-out;

			&:hover {
				background-color: $gray-100;
				cursor: pointer;
			}

			p {
				margin: 0;
				margin-left: 8px;
				color: $primary;
			}
		}

		.total-quantity {
			display: flex;
			margin-left: 10px;
			padding: 0.7rem 0.8rem;
			border: 1px solid $gray-100;
			border-radius: 8px;
			color: $gray-600;

			p {
				margin: 0;
				padding: 0;
			}

			.quantity {
				color: $primary;
			}
		}
	}

	.upper-bar-second {
		display: flex;
		align-items: center;
		margin-right: 16px;
	}
}

.delete-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.75rem 0.6rem;
	border-radius: 8px;
	background-color: #fff0ef;
	transition: all 0.15s ease-in-out;

	&:hover {
		background-color: #fddcd9;
		cursor: pointer;
	}

	img {
		margin-right: 5px;
	}

	p {
		color: #dc402c;
		font-size: 14px;
	}
}

.common-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.79rem 0.6rem;
	border-radius: 8px;
	background-color: $primary;
	transition: all 0.15s ease-in-out;

	.disabled {
		cursor: not-allowed;
	}

	&:hover {
		background-color: #4b63da;
		cursor: pointer;
	}

	&:not(.disabled):hover {
		cursor: not-allowed;
		background-color: #5e74e1a1;
	}

	img {
		margin-right: 5px;
	}

	p {
		color: white;
		font-size: 14px;
	}
}

.common-btn.light {
	background-color: #e7eafb;

	p {
		color: $primary;
	}

	&:hover {
		background-color: #d5daf8;
		cursor: pointer;
	}
}

.uncheck-all-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.75rem 0.6rem;
	border-radius: 8px;
	background-color: #e7eafb;
	transition: all 0.15s ease-in-out;

	&:hover {
		background-color: #d1d7f3;
		cursor: pointer;
	}

	img {
		margin-right: 5px;
	}

	p {
		margin: 0;
		font-size: 14px;
		color: $primary;
	}
}

// object info

.info-switch {
	// -webkit-user-select: none;
	// -moz-user-select: none;
	// user-select: none;
	margin-bottom: 24px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	background-color: $gray-200;
	// background-color: red;
	height: 55px;
	border-radius: 8px;
	color: $gray-600;

	p {
		max-width: 74px;
		max-height: 20px;
		padding: 13px 71px;
		// background-color: white;
		border-radius: 4px;
		margin-bottom: 1px;
		cursor: pointer;
		transition: 0.2s all;
	}

	.director {
		background-color: #fff;
	}

	.active {
		color: $primary;
		background-color: white;
	}
}

.more-info-container {
	font-size: 14px;

	.more-info-item {
		width: 100%;
		margin-bottom: 12px;
		padding-bottom: 12px;
		border-bottom: 1px solid $gray-200;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.more-item-name {
			color: $gray-600;
		}

		.more-item-content {
			text-align: end;
			max-width: 60%;
			// overflow: auto;
			color: $gray-1000;
		}
	}
}

.more-info-container.scrolled {
	// max-height: 50%;
	// height: 50vh;
	max-height: 76vh !important;
	height: 76vh !important;
	overflow: scroll;
	// background-color: red;

	//! changed this for employee info container
	@media screen and (max-height: 1000px) {
		height: 74vh !important;
		max-height: 74% !important;
	}

	@media screen and (max-height: 950px) {
		height: 71vh !important;
		max-height: 71% !important;
	}

	@media screen and (max-height: 826px) {
		height: 69vh !important;
		max-height: 69% !important;
	}

	@media screen and (max-height: 770px) {
		height: 67vh !important;
		max-height: 67% !important;
	}
}

.scrolled::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

//! used in employee info for contractor....

.more-info-container.scrolled-2 {
	// max-height: 50%;
	// height: 50vh;
	max-height: 83vh !important;
	height: 83vh !important;
	overflow: scroll;
	// background-color: red;

	//! changed this for employee info container
	@media screen and (max-height: 1020px) {
		height: 81vh !important;
		max-height: 81% !important;
	}

	@media screen and (max-height: 950px) {
		height: 80vh !important;
		max-height: 80% !important;
	}

	@media screen and (max-height: 826px) {
		height: 78vh !important;
		max-height: 78% !important;
	}

	@media screen and (max-height: 770px) {
		height: 77vh !important;
		max-height: 77% !important;
	}

	@media screen and (max-height: 730px) {
		height: 75vh !important;
		max-height: 75% !important;
	}
}

.scrolled-2::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.more-info-container.scrolled::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.candidate-comment {
	@media screen and (min-height: 850px) {
		position: absolute;
		bottom: 140px;
		width: 93%;
	}
}

// ahahahah
.more-info-container.scrolled-3 {
	// in requests info v2
	// max-height: 50%;
	// height: 50vh;
	// background-color: red;
	max-height: 70vh !important;
	height: 70vh !important;
	overflow: scroll;
	// background-color: red;

	@media screen and (max-height: 1020px) {
		height: 67vh !important;
		max-height: 67% !important;
	}

	@media screen and (max-height: 950px) {
		height: 62vh !important;
		max-height: 62% !important;
	}

	@media screen and (max-height: 826px) {
		height: 58vh !important;
		max-height: 58% !important;
	}

	@media screen and (max-height: 770px) {
		height: 55vh !important;
		max-height: 55% !important;
	}

	@media screen and (max-height: 730px) {
		height: 53vh !important;
		max-height: 53% !important;
	}
}

.scrolled-3::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.hidden-info {
	.blurred {
		filter: blur(4px);
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	.items {
		margin-bottom: 12px;
		display: flex;
		justify-content: space-between;
		font-size: 14px;

		.gray {
			color: $gray-600;
		}
	}

	.show-btn {
		margin-top: 18px;
		display: flex;
		justify-content: end;
		align-items: center;
		text-align: right;
		color: $primary;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;

		p {
			padding-left: 4px;
		}

		p,
		img {
			cursor: pointer;
		}
	}

	.generate-creds {
		margin-top: 8px;
		color: $primary;
		text-align: right;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;

		&:hover {
			color: #2e4bdc;
		}
	}
}

.rbt {
	position: relative;

	.dropdown-menu {
		// position: absolute;
		overflow-x: hidden;
		margin-top: 4px;
		z-index: 100;
		top: 0;
		left: 0;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

		.dropdown-item {
			font-size: 14px;
			text-decoration: none;
			color: $gray-800;

			div {
				padding: 0.8rem 0.8rem;

				&:hover {
					background-color: $gray-100;
				}
			}
		}
	}

	// background-color: red;
	// &::after {
	// 	content: "";
	// 	position: absolute;
	// 	top: 33%;
	// 	right: 3.5%;
	// 	height: 5px;
	// 	width: 10px;
	// 	background-image: url("./arrow-down-png.png");
	// 	background-repeat: no-repeat;
	// }
}

.rbt-input-main[disabled=""] {
	background-color: $gray-300;
	overflow-x: hidden;
}

// some autocompletes filters stuff
.filter-input {
	margin-bottom: 20px;
}

.filter-autocomplete-label {
	display: inline-block;
	font-size: 14px;
	margin-bottom: 4px;
	color: $gray-800;
}

.close-typeahead-filter {
	position: absolute;
	right: 13px;
	top: 55%;
	transform: translateY(-50%);
	cursor: pointer;
	filter: grayscale(100);
	opacity: 50%;
}

.typeahead-down-arrow {
	position: absolute;
	right: 12px; //! changed this
	top: 55%;
	transform: translateY(-50%);
}

.typeahead-down-arrow-not-filter {
	position: absolute;
	right: 14px;
	top: 40%;
	transform: translateY(-50%);
}

// upload objects
.example-download-container {
	display: flex;
	margin-top: 2rem;
	align-items: center;
	cursor: pointer;
	color: $primary;

	transition: all 0.1s ease-in-out;

	&:hover {
		opacity: 80%;
	}

	img {
		margin-right: 10px;
	}
}

.upload-errors {
	max-height: 65vh !important;
	overflow-y: scroll;
	margin-top: 1.5rem;
	max-width: 80%;
	display: flex;
	flex-direction: column;
}

.upload-errors::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.text-danger {
	color: $red;
}

button[disabled=""] {
	cursor: not-allowed !important;
	opacity: 50%;
}

// table pagination

.page-navigation {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-around;

	.items-shown {
		color: gray;
	}
}

.pagination {
	display: flex;
	margin-left: -100px;

	.page-item {
		text-align: center;
		list-style: none;
		margin-right: 6px;
		min-width: 20px;
		padding: 9px 9px;
		// color: $primary;
		color: $gray-800;
		border-radius: 8px;
		transition: all 0.1s ease-out;
		cursor: pointer;

		&:hover {
			background-color: #eceefa;
		}
	}

	.page-item.pagination-navigate {
		color: $primary;
	}

	.page-item.active {
		color: white;
		background-color: $primary;
	}
}

// Timesheet

.timesheet-container {
	min-height: 100%;

	.worker-name {
		margin-top: 11px;
		color: $gray-800;
		font-size: 20px;
		font-weight: 500;
	}

	.day-of-week {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 14px;

		background-color: $gray-100;
		height: 40px;
		font-weight: 600;
		font-size: 20px;
		color: $gray-600;
		border-radius: 8px;
	}

	.day-of-week.dayoff {
		color: $gray-1000;
		margin-bottom: 0;

		// @media screen and (max-height: 900px) {
		// 	margin-bottom: 5px;
		// }
	}

	.day.no-edit {
		background-color: $gray-300;
		opacity: 80%;
	}

	.day.chosen.no-edit {
		outline: none;
		cursor: default;
	}

	.day {
		position: relative;
		background-color: $gray-100;
		width: 118px;
		max-width: 118px;
		height: 92px;
		border-radius: 8px;
		text-align: center;
		color: $gray-800;
		font-size: 14px;
		cursor: pointer;

		.lock {
			position: absolute;
			right: 5px;
			top: 5px;
			opacity: 40%;
		}

		p {
			margin: 0;
			padding-top: 1px;
		}

		.hours-status {
			color: $gray-600;
			margin-bottom: 2px;
			font-size: 12px;
			font-weight: 500;
		}

		.day-num {
			padding-top: 7px;
		}

		@media screen and (max-height: 900px) {
			height: 70px;
			margin: 1px;

			p {
				margin: 0;
				padding: 0;
				font-size: 12px;
			}

			.day-num {
				padding: 0;
				padding-top: 5px;
			}

			.day-status {
				padding: 1px 0;
			}

			.day-hours {
				padding: 0;
				padding-bottom: 5px;
			}
		}

		// This stuff with colors is used in timesheet component
		// and may work weird. sorry.
		.red {
			color: $red;
		}

		.green {
			color: $green;
		}

		.gray {}
	}

	.select-chosen.gray.green {
		// a crotch
		color: $gray-800;
	}

	.day.chosen {
		outline: 1px dashed $primary;
	}

	.choose-year-month {
		width: 100%;
		display: flex;
		gap: 10px;
	}

	//bottom part
	.timesheet-set-container {
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: flex-end;
		gap: 10px;
		position: absolute;
		bottom: 46px;
		left: 16px;
	}
}

// custom select
.select-custom-container {
	position: relative;

	.select-chosen {
		min-height: 17px;
		position: relative;
		color: $gray-1000;
		padding: 0.8rem 0.8rem;
		background-color: $gray-100;
		border-radius: 8px;
		font-size: 14px;

		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;

		.arrow-down {
			position: absolute;
			top: 30%;
			right: 7.5%;
		}
	}

	.select-chosen.error {
		border: 1px solid $red;
	}

	.select-chosen.green {
		color: $green;
	}

	.select-chosen.red {
		color: $red;
	}

	.select-chosen.is-placeholder {
		color: #abb1b8;
		font-weight: 300;
	}

	.dropdown-menu {
		z-index: 9999999999;
		position: absolute;
		max-width: 100%;
		max-height: 200px;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 4px;
		width: 100%;
		top: 40px;
		left: 0;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

		.dropdown-item {
			border-bottom: 1px solid $gray-100;

			font-size: 14px;
			text-decoration: none;
			color: $gray-800;
			cursor: pointer;

			div {
				padding: 0.8rem 0.8rem;

				&:hover {
					background-color: $gray-100;
				}
			}
		}

		.dropdown-item.green {
			color: $green;
		}

		.dropdown-item.red {
			color: $red;
		}
	}

	.dropdown-menu.inverse {
		max-height: 120px;
		top: -140px;
		left: 0;

		.dropdown-item {
			div {
				padding: 0.6rem 0.8rem;
			}
		}
	}
}

// other

.divider-sm {
	width: 100%;
	border-top: 1px solid $gray-100;
	height: 15px;
}

.hide-scrollbar {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;

	::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}
}

.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

// confirm modal

.black-screen {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
}

.confirm-modal {
	position: relative;
	min-width: 310px;
	max-width: 310px;
	padding: 20px;
	padding-top: 25px;
	background-color: #fff;
	border-radius: 12px;

	.title {
		margin-top: -8px;
		font-size: 20px;
		color: $gray-800;
		margin-bottom: 16px;
	}

	.text {
		max-width: 90%;
		color: $gray-800;
		font-size: 14px;
		margin-bottom: 16px;
	}

	h3,
	p {
		margin: 0;
	}

	img {
		// close img
		position: absolute;
		top: 8px;
		right: 5px;
		padding: 10px;
		transform: scale(1.2);
		filter: grayscale(100);
		opacity: 70%;
		cursor: pointer;
	}

	.confirm-buttons {
		display: flex;
		justify-content: space-between;

		.delete-btn {
			width: 128px;
			height: 23px;
		}

		.common-btn {
			width: 128px;
			height: 21px;
		}
	}
}

// candidates list (in employeesRequests)
.candidates-list {
	padding: 16px;
	padding-top: 22px;
	margin-bottom: 12px;
	background-color: $gray-100;
	border-radius: 8px;
	font-size: 14px;

	&:hover {
		background-color: $gray-200;
		cursor: pointer;
	}

	.info {
		margin-bottom: 8px;
		display: flex;
		justify-content: space-between;
		color: $gray-1000;

		:nth-child(1) {
			color: $gray-600;
		}
	}
}

.candidates-list.active {
	outline: 1px dashed $primary;
}

// secondary of E.requests
.employee-req-secondary {
	position: relative;
	z-index: 1000;
	height: 100vh;
	width: 430px;
	max-width: 430px;

	background-color: #fff;
	padding: 0.8rem 1rem;

	.main-modal-head {
		margin-bottom: 1.3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h2 {
			font-weight: 500;
			font-size: 20px;
			color: $gray-800;
			margin: 0;
		}

		svg {
			opacity: 60%;
			cursor: pointer;
		}
	}

	.divider {
		position: absolute;
		bottom: 105px;
		left: -16px;
		min-width: 1000px;
		margin-right: 60px;
		border-bottom: 1px solid $gray-100;
		margin-bottom: 20px;
	}

	.bottom-part {
		position: absolute;
		bottom: 60px;
		left: 0;
		width: 100%;
		margin: auto;

		.common-btn {
			margin: auto;
			width: 90%;
		}

		.buttons-flex {
			display: flex;
			justify-content: center;
			align-items: center;

			.common-btn {
				margin: auto;
				width: 42%;
			}
		}
	}

	.input-validation-text {
		margin-top: 0rem;
	}
}

// worker reqs
.editReq {
	.mt-2 {
		margin-top: 8px;
	}
}

// IconInputFIlter.tsx

.icon-input-filter {
	display: flex;
	align-items: center;
	justify-content: center;

	input {
		width: 240px;
	}

	.filter-input {
		display: inline;
		width: 10px;
		margin-left: -4px;
	}

	.icon {
		width: 30px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $gray-100;
		border-radius: 8px 0px 0px 8px;

		img {
			padding-left: 5px;
		}
	}

	img {
		width: 18px;
	}
}

// divider
.divider {
	position: absolute;
	bottom: 45px;
	left: -16px;
	min-width: 1000px;
	margin-right: 60px;
	border-bottom: 1px solid $gray-100;
	margin-bottom: 20px;
}

// login
.login-container {
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: #fff;

	.login-inputs-container {
		margin: auto;

		input {
			margin-bottom: 10px;
			width: 300px;
			height: 20px;
			font-size: 17px;
			font-weight: 300;
		}

		.btn-login {
			margin-top: 0px;
			padding: 0.7rem 0rem;

			border: none;
			background-color: $primary;
			color: white;
			font-size: 17px;
			width: 100%;
			border-radius: 8px;

			&:hover {
				cursor: pointer;
				background-color: #4861df;
			}

			&:active {
				background-color: #2e4bdc;
			}
		}

		.login-error {
			margin-top: 10px;
			color: $red;
		}
	}
}

// multiple choice

.multiple-choice {
	.label-quantity {
		display: flex;
		justify-content: space-between;

		p {
			color: $gray-800;
			font-size: 14px;
		}
	}
}

.multiple-container {
	position: relative;

	.select-chosen.error {
		border: 1px solid $red;
	}

	.select-chosen {
		// display: flex;

		min-height: 17px;
		max-height: 100px;
		overflow-y: scroll;
		position: relative;
		color: $gray-1000;
		padding: 0.8rem 0.8rem;
		background-color: $gray-100;
		border-radius: 8px;
		font-size: 14px;

		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;

		.arrow-down {
			position: absolute;
			top: 13px;
			right: 7.5%;
		}

		.chosen-options-container {
			z-index: 9999999;
			max-width: 92%;
			display: flex;
			flex-wrap: wrap;
			gap: 5px;

			.chosen-options {
				min-height: 15px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0.3rem 0.4rem;
				color: $primary;
				background-color: #e4e9fd;
				transition: all 0.1s ease-in-out;
				cursor: pointer;

				&:hover {
					background-color: #cad4fb;
				}

				img {
					margin-left: 3px;
					height: 18px;
					filter: grayscale(100);
				}
			}
		}
	}

	.select-chosen::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}

	.all {
		display: flex;

		p {
			padding: 5px 10px 0px 0px;
			font-size: 14px;
			color: $gray-800;
			transition: all 0.1s ease-in-out;

			&:hover {
				color: $gray-1000;
				cursor: pointer;
			}
		}

		p.choose-all {
			color: $primary;
		}

		// p.clear {
		// 	color: $red;
		// }
	}

	.dropdown-menu {
		position: absolute;
		max-width: 100%;
		max-height: 200px;
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 4px;
		z-index: 100;
		width: 100%;
		// top: 40px;

		left: 0;
		background-color: white;
		border-radius: 8px;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);

		.dropdown-item {
			border-bottom: 1px solid $gray-100;
			font-size: 14px;
			text-decoration: none;
			color: $gray-800;

			div {

				// padding: 0.8rem 0.8rem;
				&:hover {
					background-color: $gray-100;
				}
			}

			.dropdown-item-flex {
				display: flex;
				align-items: center;

				img {
					margin-top: 4px;
					margin-right: 10px;
				}
			}
		}

		.dropdown-item {
			div {
				padding: 0.4rem 0.8rem;
			}
		}
	}
}

// loading
.loading {
	position: relative;
	min-height: 80vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.kitty {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -54%);
		border-radius: 99999999px;
		opacity: 70%;
	}

	.loader {
		animation: spin 1.7s linear infinite;
		z-index: 10;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loading-inside {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.kitty {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 99999999px;
		opacity: 70%;
	}

	.loader {
		animation: spin 1.7s linear infinite;
		z-index: 10;
	}
}

// etc

div.outlet-create-no-scroll {
	// max-height: vh !important;
	height: 80vh !important;
	min-height: 0vh !important;
	overflow-x: hidden;
	overflow-y: scroll;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;

	&::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}
}

div.outlet-create-no-scroll::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.color-gray-text {
	color: $gray-1000;
}

// container in modal, good one i hope

// .side-modal-container {
// 	// display: flex;
// 	// flex-direction: column;
// 	// justify-content: space-between;
// 	// min-height: 80vh;
// 	// background-color: red;
// 	height: 100%;
// }
// .bottom-container {
// 	z-index: 10;
// 	position: absolute;
// 	bottom: 25px;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	// justify-content: center;
// 	// background-color: #fff;
// 	background-color: red;
// 	left: 50%;
// 	transform: translateX(-50%);
// 	height: 80px;
// 	width: 100%;

// 	.inner-bottom-container {
// 		width: 95%;
// 	}
// }

// .fuckSuka {
// 	min-height: 100vh;
// }

.side-modal-container {
	.center-container {
		max-height: 77vh;
		overflow-y: scroll;
		overflow-x: hidden;

		@media screen and (max-height: 980px) {
			max-height: 70vh;
		}

		@media screen and (max-height: 870px) {
			max-height: 65vh;
		}
	}

	.center-container:root {
		scrollbar-width: none !important;
	}

	.bottom-container {
		position: absolute;
		width: 95%;
		bottom: 40px;
		left: 12px;
		scrollbar-width: none;
	}

	.center-container::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}

	// display: flex;
	// justify-content: center;
}

// another one...
.side-modal-container {
	.center-container-2 {
		max-height: 90vh;
		overflow-y: scroll;
		overflow-x: hidden;

		@media screen and (max-height: 980px) {
			// max-height: 70vh;
		}

		@media screen and (max-height: 870px) {
			// max-height: 65vh;
		}
	}

	.bottom-container-2 {
		position: absolute;
		width: 95%;
		bottom: 40px;
		left: 12px;
		scrollbar-width: none;

		.flex {
			display: flex;
			justify-content: space-around;

			.common-btn {
				width: 42%;
				height: 17px;
			}
		}
	}

	.bgred {
		background-color: red;
	}

	.center-container-2::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}

	// and another one....... (in CreateRates)
}

// display: flex;
// justify-content: center;
.side-modal-container {
	.center-container-3 {
		max-height: 77vh;
		overflow-y: scroll;
		overflow-x: hidden;

		@media screen and (max-height: 980px) {
			// max-height: 70vh;
		}

		@media screen and (max-height: 870px) {
			// max-height: 65vh;
		}
	}

	.bottom-container-3 {
		position: absolute;
		width: 95%;
		bottom: 40px;
		left: 12px;
		scrollbar-width: none;

		.flex {
			display: flex;
			justify-content: space-around;
			align-items: center;

			.common-btn {
				width: 42%;
				height: 17px;
			}
		}
	}

	.center-container-3::-webkit-scrollbar {
		width: 0 !important;
		display: none;
	}

	// display: flex;
	// justify-content: center;
}

// flex-employee-info. btn 100% -- inline style
.flex-employee-info {
	display: flex;
	justify-content: space-between;
	position: absolute;
	width: 93%;
	bottom: 40px;

	.common-btn {
		width: 44%;
	}

	@media screen and (max-width: 1430px) {
		.common-btn {

			// transform: scale(0.9);
			// padding: 10px 0px;
			p {
				font-size: 0.7rem;
			}
		}
	}
}

//  font in the table for responsive screens
.table-responsive-sm {
	@media screen and (max-width: 1300px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 1200px) {
		font-size: 0.7rem;
	}
}

input.general-search-sm {
	width: 140px;
}

.employees-working-period-block {
	display: flex;
	margin: auto;
	margin-left: 15px;
	// align-items: center;
	// justify-content: center;

	.start {
		position: relative;
	}

	.end {
		position: relative;
		margin-left: 10px;
	}

	input {
		width: 130px;
		// height: 10px;
		// padding-top: 10px;
		// padding-bottom: 10px;
	}

	.period-label {
		width: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		color: $gray-600;
		font-size: 0.64rem;
		text-align: center;
	}
}

.hrs-confirm-btns-container {
	display: flex;
	gap: 20px;
	// margin-right: 40px;

	.hrs-confirm-btn {
		color: white;
		// background-color: $gray-500;
		padding: 12px 10px;
		border-radius: 8px;
		cursor: pointer;
	}

	.positive {
		// background-color: rgb(94, 115, 225, 0.4);
		color: $primary;
		// border: 1px solid $primary;
		transition: all 0.3s ease-in-out;
		opacity: 40%;
	}

	.positive.active {
		// background-color: rgb(94, 115, 225, 0.75);
		color: $primary;
		// border: 1px solid $primary;
		opacity: 100%;
		background-color: #e7eafb;
		color: $primary;

		&:hover {
			background-color: #bac4f1;

			// color: white;
		}
	}

	.negative {
		opacity: 40%;

		// background-color: rgba(220, 52, 30, 0.4);
		// border: 1px solid $red;
		color: #dc402c;
		background-color: #fddcd9;
		transition: all 0.3s ease-in-out;
	}

	.negative.active {
		// background-color: rgba(220, 52, 30, 0.75);
		opacity: 100%;

		&:hover {
			background-color: #fabbb5;
		}
	}
}

.divider-hide-if-screen-low-height {
	@media screen and (max-height: 1000px) {
		display: none;
	}
}

// heading name and red dot
.heading-container {
	position: relative;

	.notification-dot {
		position: absolute;
		top: -10px;
		right: 25px;
		height: 12px;
		width: 12px;
		// background-color: $red;
		opacity: 75%;
		border-radius: 100px;
	}
}

.user-card {
	background-color: #f7f8fa;
	margin: 3px;
	border-radius: 10px;
	padding: 7px 14px;
	display: flex;
	flex-direction: row;
	margin-right: 10px;
	gap: 15px;
		font-size: 14px;
	color: #7e8794;
  }
  
  .user-card > div:nth-child(1) {
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: center;
  }
  
  
  .user-card .exit-btn {
	color: white;
	padding: 9px 6px;
	padding-right: 0px;
  }