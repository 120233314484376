// gives an error :(

.nav-link {
	padding-left: 0 !important;
}

.autocomplete-container {
	position: relative;
	.autocomplete-options:first-child {
		padding-top: 100px !important;
	}
	.autocomplete-options {
		z-index: 10;

		max-height: 200px;
		overflow-y: scroll;
		position: absolute;
		width: 200%;
		background-color: white;
		border-bottom: 1px rgb(192, 192, 192) solid;
		border-right: 1px rgb(192, 192, 192) solid;
		border-left: 1px rgb(192, 192, 192) solid;

		border-radius: 5px;

		.autocomplete-item {
			top: 0;
			left: 0;
			padding-left: 12px;
			// font-size: 1.2rem;
			color: rgb(49, 49, 49);
			cursor: pointer;
			margin-bottom: 0;
			padding-top: 10px;
			padding-bottom: 10px;

			&:hover {
				background-color: rgb(233, 232, 232);
			}
		}
	}
	.purge-autocomplete {
		position: absolute;
		top: 1px;
		border-radius: 0px 5px 5px 0px;
		right: 1px;
		background-color: rgb(216, 216, 216);
		padding: 6px;
		padding-left: 10px;
		padding-right: 10px;
		cursor: pointer;

		&:hover {
			background-color: rgb(203, 201, 201);
		}
	}
}

.hidden {
	display: none !important;
}

.page-link {
	cursor: pointer !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.dropdown-item {
	padding: 0 !important;

	div {
		padding: 6px 10px;
	}
}

.show-more-info-text {
	text-decoration: underline;
	cursor: pointer;
	// margin-top: 2rem;
	margin-bottom: 2rem;
}
//! remove button multiple choice
.rbt-token-remove-button {
	display: none;
	transform: scale(0.7);
	span {
		display: none;
	}
}

.rbt-input-multi {
	max-width: 100%;
}
// .rbt-input-wrapper {
// 	display: flex;
// 	flex-direction: row !important;
// 	max-width: 300px;
// 	over
// }
.rbt-token-removeable {
	display: flex;
}
// .btn:fo,
// .btn:active {
// 	outline: none !important;
// 	box-shadow: none !important;
// }

// a:active {
// 	outline: none !important;
// }

.nav-link {
	outline: none !important;
}

.link-show-more {
	color: black;
}

.generate {
	text-decoration: underline;
	p {
		margin-bottom: 0;
	}
}

.active-link {
	font-weight: bold;
}

// TYPEAHED
.rbt-loader {
	display: none !important;
}

.accept-or-not-employees-reqs {
	display: flex;
	margin: auto;
	.noexistaccept {
		min-width: 18px;
	}
	div {
		display: inline-block;
		// max-width: 10px;
	}
}

// .rbt-input-main[disabled=""] {
// 	opacity: 0;
// }

.rbt-input-fuckyou .rbt-input-multi {
	background-color: #e4e4e4;
}

.choose-delte-all {
	text-decoration: underline;
	cursor: pointer;
	max-width: 100px;
	&:hover {
		color: gray;
	}
}

.show-more-info-emp {
	// display: inline-block;
	max-width: 300px;
}

.text-danger {
	background-color: unset !important;
}

.text-success {
	background-color: unset !important;
}

.dl {
	text-decoration: underline;
	cursor: pointer;
}

.text-v-sm {
	@media screen and (max-width: 1800px) {
		font-size: 0.9rem;
	}

	@media screen and (max-width: 1500px) {
		font-size: 0.85rem;
	}
	@media screen and (max-width: 1400px) {
		font-size: 0.78rem;
	}
}

// .text-vv-sm {
// 	@media screen and (max-width: 1800px) {
// 		font-size: 0.8rem;
// 	}

// 	@media screen and (max-width: 1500px) {
// 		font-size: 0.78rem;
// 	}
// 	@media screen and (max-width: 1400px) {
// 		font-size: 0.7rem;
// 	}
// }

.sheet-options p {
	margin-bottom: 0 !important;
	background-color: unset !important;
}
.sheet-options div {
	margin-bottom: 0 !important;
	padding: 0;
}

// .th-item {
// 	display: flex;
// 	min-height: 100% !important;
// 	flex-shrink: 1;
// }

// phone number field for firefox.
@-moz-document url-prefix() {
	.phonefield-container input {
		height: 15px;
	}
}

@-moz-document url-prefix() {
	.phonefield-container-gb input {
		height: 15px;
	}
}

.phonefield-container {
	z-index: 1000;
	position: relative;

	&::before {
		font-size: 14px;
		content: "+7" !important;
		z-index: 10;
		position: absolute !important;
		top: 15px !important;
		left: 10px !important;
	}
	.phonefield {
		max-width: 92%;
		position: relative;
		padding-left: 26px;
	}
}

.phonefield-container-gb {
	z-index: 1000;
	position: relative;

	&::before {
		font-size: 14px;
		content: "+44" !important;
		z-index: 10;
		position: absolute !important;
		top: 15px !important;
		left: 10px !important;
	}
	.phonefield {
		max-width: 92%;
		position: relative;
		padding-left: 33px;
	}
}

.text-gray {
	color: rgb(88, 87, 87);
}
