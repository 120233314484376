.css-grid-container-week {
	margin-top: 24px;
	max-width: 1000px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	gap: 10px;

	@media screen and (max-height: 900px) {
		// gap: 0px;
		height: 65vh;
		max-height: 65vh;
		overflow-y: scroll;
	}

	// @media screen and (max-height: 900px) {
	// 	height: 70vh;
	// 	max-height: 70vh;
	// 	overflow-y: hidden;
	// }

	p {
		text-align: center;
		padding-top: 5px;
		padding-bottom: 5px;
		border-radius: 5px;
	}
	.weekday {
		// background-color: $gray-400;
	}
}
.css-grid-container-week::-webkit-scrollbar {
	width: 0 !important;
	display: none;
}

.calendar-form {
	position: relative;

	span {
		position: absolute;
		top: 0%;
		right: 0px;
		padding: 7px;
		border-radius: 0px 10px 10px 0px;

		// background-color: $gray-300;
	}
}

.outer {
	z-index: 100;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.372);
	.inner {
		position: relative;
		height: 250px;
		width: 450px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: rgb(243, 242, 242);
		border-radius: 10px;
		box-shadow: 0px 5px 10px 2px rgba(13, 13, 13, 0.2);
		transition: all 200ms ease-in;
		&:hover {
			box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.37);
		}
		.inner-inner {
			margin-top: 2rem;
			width: 60%;
			input {
				margin-bottom: 1rem;
			}
			select {
				margin-bottom: 1rem;
			}
		}
		.close-btn-modal {
			cursor: pointer;
			position: absolute;
			top: 15px;
			right: 20px;
		}
	}
}

.go-back-timesheet {
	color: rgb(74, 74, 74);
	font-weight: 700;
}
