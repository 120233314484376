.time-table-head-outer-container {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.9rem;
  width: 100vw;
  height: 36px;
  box-sizing: border-box;
  color: #474f5a;
}
.time-table-head-outer-container .info {
  margin-right: 8px;
  width: 30%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
}
@media screen and (min-width: 1600px) {
  .time-table-head-outer-container .info {
    width: 20%;
  }
}
.time-table-head-outer-container .info p {
  text-align: center;
  padding: 0rem 0.5rem;
}
.time-table-head-outer-container .h-table-container {
  width: 62%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
}
@media screen and (min-width: 1600px) {
  .time-table-head-outer-container .h-table-container {
    width: 72%;
  }
}
.time-table-head-outer-container .h-table-container .h-table-date-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5%;
  border-right: 1px solid #f7f8fa;
}
.time-table-head-outer-container .h-table-container .h-table-date-container .h-table-date {
  text-align: center;
  margin-bottom: 0px;
}
.time-table-head-outer-container .h-table-container .h-table-date-container:last-child {
  border-right: none;
}
.time-table-head-outer-container .statistics-h {
  margin-left: 8px;
  width: 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  text-align: center;
}
.time-table-head-outer-container .statistics-h p {
  text-align: center;
  padding: 0rem 0.5rem;
}

.time-table-body-outer-container::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.time-table-body-outer-container {
  max-height: 75vh;
  overflow-y: scroll;
  margin-top: 8px;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.9rem;
  width: 100vw;
  display: flex;
  color: #474f5a;
  box-sizing: border-box;
}
.time-table-body-outer-container .info {
  margin-right: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 30%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  text-align: center;
  border-radius: 8px;
}
@media screen and (min-width: 1600px) {
  .time-table-body-outer-container .info {
    width: 20%;
  }
}
.time-table-body-outer-container .info .info-part {
  width: 90%;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
}
.time-table-body-outer-container .info .info-part .gray {
  color: #7e8794;
}
.time-table-body-outer-container .h-table-container {
  width: 62%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 1600px) {
  .time-table-body-outer-container .h-table-container {
    width: 72%;
  }
}
.time-table-body-outer-container .h-table-container .presence-container {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #f7f8fa;
  border-radius: 8px 8px 0px 0px;
}
.time-table-body-outer-container .h-table-container .presence-container .h-table-date {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 0px;
  width: 3.5%;
  border-right: 1px solid #f7f8fa;
}
.time-table-body-outer-container .h-table-container .presence-container .h-table-date:last-child {
  border-right: none;
}
.time-table-body-outer-container .h-table-container .h-table-dates {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #fff;
  border-radius: 0px 0px 8px 8px;
}
.time-table-body-outer-container .h-table-container .h-table-dates .h-table-date {
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
  margin-bottom: 0px;
  width: 3.5%;
  border-right: 1px solid #f7f8fa;
}
.time-table-body-outer-container .h-table-container .h-table-dates .h-table-date:last-child {
  border-right: none;
}
.time-table-body-outer-container .statistics-h {
  margin-left: 8px;
  width: 8%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.time-table-body-outer-container .statistics-h .statistics-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
  width: 48%;
  border-radius: 8px;
}
.time-table-body-outer-container .statistics-h .statistics-info .info-part {
  height: 33%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.time-table-body-outer-container .statistics-h .edit {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 48%;
  border-radius: 8px;
  border-color: white;
  transition: all 0.13s ease-in-out;
}
.time-table-body-outer-container .statistics-h .edit:hover {
  cursor: pointer;
  transition: all 0.13s ease-in-out;
}
.time-table-body-outer-container .statistics-h .edit:hover img {
  transform: scale(1.1);
  transition: all 0.13s ease-in-out;
}
.time-table-body-outer-container .statistics-h .edit img {
  transition: all 0.13s ease-in-out;
  width: 22px;
  height: 22px;
}
.time-table-body-outer-container .statistics-h p {
  text-align: center;
  padding: 0rem 0.5rem;
}
.time-table-body-outer-container .green {
  color: #3dd598;
}
.time-table-body-outer-container .red {
  color: #ff3b30;
}/*# sourceMappingURL=TimeTable.css.map */