.form-container {
	margin-top: 5rem;
	display: flex;
	justify-content: center;

	.text-form {
		margin-bottom: 2rem;
		text-align: center;
	}
	.form-inner {
		margin-bottom: 3rem;
		padding: 2rem 4rem;
		// background-color: rgb(250, 250, 250);
		background-color: white;
		min-width: 550px;
		box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
		border-radius: 10px;
		transition: all 200ms ease-in;

		.more-info {
			max-height: 150px;
			overflow-y: scroll;
			.gray-text-mini {
				color: rgb(99, 99, 99);
				margin-bottom: 2px;
				font-size: 0.9rem;
			}
			h4 {
				font-size: 1.2rem;
				margin-bottom: 0.3rem;
				text-decoration: underline;
			}
			h5 {
				font-size: 1rem;
				margin-bottom: 0.1rem;
			}
		}

		&:hover {
			box-shadow: 0px 5px 15px 2px rgba(34, 60, 80, 0.2);
			transition: all 200ms ease-in;
		}
	}
}

.form-inner {
	input {
		margin-bottom: 20px;
	}

	.create-btn {
		position: absolute;
		right: 20px;
		bottom: 45px;
	}
}
