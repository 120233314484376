.modal-feedback {
  max-width: 500px;
  z-index: 99999999;
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
}
.modal-feedback .type {
  height: 30px;
}
.modal-feedback .info {
  margin: 0px 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-feedback .info h3 {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
}
.modal-feedback .info p {
  margin-bottom: 10px;
}
.modal-feedback .close {
  height: 25px;
  filter: grayscale(100);
  opacity: 65%;
  cursor: pointer;
}/*# sourceMappingURL=modalFeedback.css.map */