.grid-filters {
	display: grid;
	max-width: 80%;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: 10px;
	row-gap: 10px;

	.grid-filters-item {
		max-width: 280px;
	}
}
